import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BuyThankYouModalComponent} from '../buy-thank-you-modal/buy-thank-you-modal.component';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {
  @Input() amount: number;
  form: FormGroup;

  public paymentMethods = [
    {
      logoUrl: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/icons/visa-logo.svg',
      active: false
    },
    {
      logoUrl: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/icons/mc-logo.svg',
      active: false
    },
    {
      logoUrl: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/icons/dn-logo.svg',
      active: false
    },
    {
      logoUrl: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/icons/ae-logo.svg',
      active: false
    },
    {
      logoUrl: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/icons/jcr-logo.svg',
      active: false
    },
    {
      logoUrl: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/icons/paypal-logo.svg',
      active: false
    },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private modal: NgbModal
  ) {
  }

  makeActive(index) {
    for (let button of this.paymentMethods) {
      button.active = false;
    }
    this.paymentMethods[index].active = !this.paymentMethods[index].active;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.activeModal.dismiss();
      const ref = this.modal.open(BuyThankYouModalComponent, {centered: true, windowClass: 'thank-you-modal'});
      ref.componentInstance.amount = this.amount;
    }
  }

  ngOnInit() {
    this.form = new FormGroup({
      card: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required, Validators.pattern('^\\d{2}\\/\\d{2}$')]),
      cvc: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{3}$')]),
      useNumber: new FormControl()
    });
  }

}
