import { NgModule } from '@angular/core';
import {BarLayoutComponent} from './bar-layout.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
BarLayoutComponent
  ],
  imports: [
    RouterModule
  ],
  exports: [
   BarLayoutComponent
  ],
  entryComponents: [
  ]
})
export class BarLayoutModule { }
