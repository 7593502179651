import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken = localStorage.getItem('user_token');
    const modifiedReq = req.clone(
      {
        headers: req.headers.set('Authorization', `Bearer ${userToken}`),
      }
    );
    return next.handle(modifiedReq);
  }
}
