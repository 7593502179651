import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MakePaymentComponent} from './make-payment.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ThankYouModalModule} from '../thank-you-modal/thank-you-modal.module';
import {ThankYouModalComponent} from '../thank-you-modal/thank-you-modal.component';
import {BuyThankYouModalComponent} from '../buy-thank-you-modal/buy-thank-you-modal.component';
import {BuyThankYouModalModule} from '../buy-thank-you-modal/buy-thank-you-modal.module';

@NgModule({
  declarations: [
    MakePaymentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BuyThankYouModalModule
  ],
  exports: [
    MakePaymentComponent
  ],
  entryComponents: [
    BuyThankYouModalComponent
  ]
})
export class MakePaymentModule {
}
