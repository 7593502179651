import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
public isAuthenticated = !!localStorage.getItem('user_token');

  constructor(
    private http: HttpClient
  ) { }

  getUserPortfolioValue() {
    return this.http.get(`https://weown-api.pioneersa.org/api/user/portfolio_value`);
  }

  postUserAction(actionInfoArr: Array<any>) {
    return this.http.post(`https://weown-api.pioneersa.org/admin/user_actions`, {
      user_action: actionInfoArr[0],
      page_name: actionInfoArr[1],
      page_url: 'http://tenant-weown.pioneersa.org/',
      profile_value: actionInfoArr[2],
      wallet_value: actionInfoArr[3],
      user_type: actionInfoArr[4]
    });
  }
}
