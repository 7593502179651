import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {ApiService} from "../../services/api.service";
import {Subscription} from "rxjs";
import {UserInfo} from "./header/header.component";
import {StorageService} from "../../services/storage.service";

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy, AfterViewChecked {
  infoSubscription: Subscription;
  data: UserInfo = {
    userName: '',
    photoUrl: ''
  };

  public iconsList = [
    {
      url: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/header-default/1.png',
      text: 'Watchlist',
      link: '/watchlist/listings'
    },
    {
      url: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/header-default/2.png',
      text: 'Notifications',
      link: '/notifications-settings'
    },
    {
      url: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/header-default/3.png',
      text: 'Settings',
      link: '/account-settings'
    },
    {
      url: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/header-default/4.png',
      text: 'Dashboard',
      link: 'profile'
    },
    {
      url: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/header-default/5.png',
      text: 'Logout',
      link: '/select-user'
    }
  ];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private storage: StorageService,
    private changeDetector: ChangeDetectorRef
  ) {

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.storage.showLoader();
      }
    });
  }


  showResults() {
    this.router.navigate(['/search-page']);
  }

  ngOnInit() {
    this.infoSubscription = this.apiService.getUserData().subscribe((res: any) => {
      this.data = {
        userName: res.full_name,
        photoUrl: res.profile_photo
      };
    });
  }

  onActivate() {
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.infoSubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
}
