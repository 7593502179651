import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = 'https://weown-api.pioneersa.org';

  constructor(
    private http: HttpClient
  ) {
  }

  getMyRentData() {
    return this.http.get(`${this.baseUrl}/api/tenant/my_rent`);
  }

  changeTenantInfo(body) {
    return this.http.put(`${this.baseUrl}/api/tenant/settings`, body);
  }

  getTenantInfo() {
    return this.http.get(`${this.baseUrl}/api/tenant/settings`);
  }

  getPaymentHistory() {
    return this.http.get(`${this.baseUrl}/api/tenant/rent/payment_history`);
  }

  getReportIssuesInfo(listingId) {
    return this.http.get(`${this.baseUrl}/api/tenant/report_issues/${listingId}`);
  }

  postIssue(body) {
    return this.http.post(`${this.baseUrl}/api/tenant/report_issues`, body);
  }

  payRent(body) {
    return this.http.post(`${this.baseUrl}/api/tenant/pay_rent`, body);
  }

  getBankAccountInfo() {
    return this.http.get(`${this.baseUrl}/api/bank_account`, {params: {user_type: 'tenant'}});
  }

  changeBankAccountInfo(body) {
    return this.http.put(`${this.baseUrl}/api/bank_account`, body, {params: {user_type: 'tenant'}});
  }

  addBankAccountInfo(body) {
    return this.http.post(`${this.baseUrl}/api/bank_account`, body, {params: {user_type: 'tenant'}});
  }

  getInvestorDocuments() {
    return this.http.get(`${this.baseUrl}/api/documents`, {params: {user_type: 'tenant'}});
  }

  uploadDocuments(body) {
    return this.http.post(`${this.baseUrl}/api/document`, body);
  }

  changeDocuments(body, id) {
    return this.http.put(`${this.baseUrl}/api/document/${id}`, body);
  }

  getOwnInfo(id) {
    return this.http.get(`${this.baseUrl}/api/investor/${id}`);
  }

  getUserData() {
    return this.http.get(`${this.baseUrl}/api/user/short_info`);
  }
}
