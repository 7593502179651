import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public isLoaded = false;

  constructor() {
  }

  hideLoader() {
    this.isLoaded = true;
  }

  showLoader() {
    this.isLoaded = false;
  }
}
