import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ThankYouModalComponent} from './thank-you-modal.component';


@NgModule({
  declarations: [
    ThankYouModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    ThankYouModalComponent
  ]
})
export class ThankYouModalModule {
}
