import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-layout',
  templateUrl: './bar-layout.component.html',
  styleUrls: ['./bar-layout.component.scss']
})
export class BarLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onActivate() {
    window.scroll(0,0);
  }
}
