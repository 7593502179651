import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../services/auth.service';

export interface UserInfo {
  userName: string;
  photoUrl: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Input() data: UserInfo;

  public iconsList = [
    {
      url: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/header-default/2.png',
      text: 'Notifications',
      link: '/notifications'
    },
    {
      url: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/header-default/3.png',
      text: 'Settings',
      link: '/edit-profile'
    },
    {
      url: 'https://weown-desktop.s3.eu-central-1.amazonaws.com/header-default/5.png',
      text: 'Logout',
      action: () => {
        this.authService.getUserPortfolioValue()
          .subscribe(
            (response: any) => {
              const profileValue = response.portfolio_value;
              const walletValue = response.funds;
              const actionInfo = ['Logout', 'Logout', profileValue, walletValue, 'tenant'];

              this.authService.postUserAction(actionInfo).subscribe(
                () => {
                  localStorage.clear();
                  this.authService.isAuthenticated = false;
                  window.location.href = 'http://weowndesk.pioneersa.org/login';
                }
              );
            }
          );
      }
    }
  ];

  constructor(
    private router: Router,
    private modal: NgbModal,
    private authService: AuthService
  ) {
  }


  ngOnInit() {
  }

  act(button) {
    if (button.action) {
      button.action();
    } else {
      this.router.navigate([button.link]);
    }
  }

}
