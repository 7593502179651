import { NgModule } from '@angular/core';
import {DefaultLayoutComponent} from './default-layout.component';
import {RouterModule} from '@angular/router';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {ThankYouModalModule} from '../../components/modals/thank-you-modal/thank-you-modal.module';
import {ThankYouModalComponent} from '../../components/modals/thank-you-modal/thank-you-modal.component';
import {DefaultLayoutRouting} from './default-layout.routing';
import {MakePaymentComponent} from '../../components/modals/make-payment/make-payment.component';
import {MakePaymentModule} from '../../components/modals/make-payment/make-payment.module';
import {BarLayoutModule} from '../bar-layout/bar-layout.module';

@NgModule({
  declarations: [
    DefaultLayoutComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    RouterModule,
    NgbDropdownModule,
    CommonModule,
    ThankYouModalModule,
    MakePaymentModule,
    BarLayoutModule,
    RouterModule.forChild(DefaultLayoutRouting)
  ],
  exports: [
    DefaultLayoutComponent
  ],
  entryComponents: [
    ThankYouModalComponent,
    MakePaymentComponent
  ]
})
export class DefaultLayoutModule { }
