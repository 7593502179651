import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DefaultLayoutComponent} from './layout/default-layout/default-layout.component';
import {BarLayoutComponent} from './layout/bar-layout/bar-layout.component';
import {GuardGuard} from "./services/guard.guard";


const routes: Routes = [
  {
    path: '', component: DefaultLayoutComponent, canActivate: [GuardGuard], children: [
      {
        path: '', component: BarLayoutComponent, children: [
          {
            path: 'report-issue/:id',
            loadChildren: () => import('./components/report-issue/report-issue.module').then(m => m.ReportIssueModule)
          },
          {
            path: 'payment-history',
            loadChildren: () => import('./components/payment-history/payment-history.module').then(m => m.NotificationsModule)
          },
          {
            path: 'agreements',
            loadChildren: () => import('./components/agreements/agreements.module').then(m => m.AgreementsModule)
          },
          {
            path: '',
            loadChildren: () => import('./components/my-rent/my-rent.module').then(m => m.MyRentModule)
          }
        ]
      },
      {
        path: 'notifications',
        loadChildren: () => import('./components/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {path: 'edit-profile', loadChildren: () => import('./components/edit-profile/edit-profile.module').then(m => m.EditProfileModule)},
      {path: 'support', loadChildren: () => import('./components/support/support.module').then(m => m.SupportModule)},
      {path: 'faq', loadChildren: () => import('./components/faq/faq.module').then(m => m.FaqModule)},
      {path: 'about', loadChildren: () => import('./components/about/about.module').then(m => m.AboutModule)}
    ]
  },
  {path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
